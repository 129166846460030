import Config from './config.json'
import getSDKURL from './sdkHost'
import appendScript from './jsscript'
import appendStyle from './style'

let widgetsJsIsReady = false
let widgetReadyCallbackCalled = false

window.APWWidget = {
  onReady(widgetReadyCallback) {
    this.widgetReadyCallback = widgetReadyCallback

    if (!widgetReadyCallbackCalled && widgetsJsIsReady) {
      widgetReadyCallback(window[Config.widgetGlobalName])
      widgetReadyCallbackCalled = true
    }
  },
}

const widgetScriptReadyCallback = () => {
  const { widgetReadyCallback } = window.APWWidget
  widgetsJsIsReady = true

  if (widgetReadyCallback && !widgetReadyCallbackCalled) {
    widgetReadyCallback(window[Config.widgetGlobalName])
    widgetReadyCallbackCalled = true
  }
}

const { passportPathUrl } = getSDKURL()
// eslint-disable-next-line no-undef
passportPathUrl.searchParams.append('v', RESOURCE_VERSION)
appendScript(passportPathUrl.href, () => {
  const widgetCDNURL = `${window.APWWidget.passportCdnUrl}/${Config.publicPath}`
  const appCDNURL = `${window.APWWidget.passportCdnUrl}/${Config.appPublicPath}`
  window.passportCDNURL = widgetCDNURL // eslint-disable-line
  window.passportAppCDNURL = appCDNURL // eslint-disable-line
  window.passportAPIServerHost = window.APWWidget.passportUrl // eslint-disable-line
  window.keycloakAPIServerHost = window.APWWidget.keycloakUrl // eslint-disable-line

  // eslint-disable-next-line no-undef
  appendScript(`${widgetCDNURL}Widgets.min.js?v=${RESOURCE_VERSION}`, widgetScriptReadyCallback)
  // eslint-disable-next-line no-undef
  appendStyle(`${widgetCDNURL}Widgets.min.css?v=${RESOURCE_VERSION}`)
})
